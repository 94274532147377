.filter--search-refine {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ccc;
    padding: 0;
    max-height: 12rem;
    width: 100%;
    display: inline-block;

    @include mq($from: tablet) {
        max-height: 3rem;

    }

    .button {
        display: none;
    }

}


.filter__section {
    &--checkboxes {
        display: block;

        @include mq($from: tablet) {
            display: flex;

        }

        & p {
            font-size: 1rem;
            margin-right: 2rem;
            font-weight: 600;
        }
    }
}


.row-list {
    margin-top: 1rem !important;

}

.row-list.row--result-list {
    margin-top: 15.5rem !important;

    @include mq($from: tablet) {
        margin-top: 5rem !important;
    }
}



.list-filter-sort select {
    border-color: #ccc !important;
}

.form-field--checkbox {
    width: 100%;
    margin-right: 1.5rem;

    @include mq($from: tablet) {
        margin-right: 1.3rem;
        width: fit-content;
    }

    @include mq($from: desktop) {
        margin-right: 3.5rem;
        width: fit-content;
    }
}

.sort-by {
    margin-top: 1.3rem;
}

.row-search-filters .form-checkbox {
    height: 25px;
    width: 25px;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 20px;
}

.row-search-filters .form-checkbox:hover {
    background-color: $primary-color;
    color: white;
    border-color: $primary-color;
}

.row-search-filters .form-checkbox:checked {
    background-color: $primary-color;
    color: white;
    border-color: $primary-color;
}

.pagination a {
    padding: .5rem 2rem;
    border: 2px solid $primary-color;
    border-radius: 25px;
    font-weight: 500;
    color: $primary-color;
    margin-top: 1.5rem;
}

.pagination a:hover {
    background-color: $primary-color;
    color: white;

}

.filter__reset {
    float: right;
}

.form-field--checkbox label {
    font-size: 1rem;
    color: $nearly-black;
}

.filter__form--collapsible {
    display: flex;
    flex-direction: row;

    @include mq($from: tablet) {
        flex-direction: column;
    }
}