.paralle-BG-dark {
    width: 100%;
    height: auto;
    transform: skew(0deg, -5deg);
    background-color: #413F3F;
    color: white;
    padding: 50px 0;
}

.paralle-BG-dark {
    .article-main-image {
        transform: skew(0deg, 5deg);
        border-radius: .5rem;
        border: 20px solid white;
        box-shadow: 0 1px 10px 0 rgb(0 0 0 / 15%);
        margin: -2rem 0 -2rem 0;

        &--no-border {
            transform: skew(0deg, 5deg);
            border: none;

            img {
                margin-bottom: -3rem;
            }
        }
    }

    .code {
        width: 320px;
        height: 320px;
        transform: skew(0deg, 5deg);
        border-radius: .5rem;
        border: 10px solid white;
        box-shadow: 0 1px 10px 0 rgb(0 0 0 / 15%);
        margin: 0 auto;

        @include mq($from: tablet) {
            width: 730px;
            height: 430px;
            border: 15px solid white;
        }

        @include mq($from: desktop) {
            width: 1040px;
            height: 840px;
            border: 20px solid white;
        }

        iframe {
            width: 300px;
            height: 300px;

            @include mq($from: tablet) {
                width: 700px;
                height: 400px;
            }

            @include mq($from: desktop) {
                width: 1000px;
                height: 800px;
            }
        }

    }
}

.our-products-left,
.our-products-right {
    transform: skew(0deg, 5deg);
    padding-top: 80px;
    text-align: left;
}

.paralle-BG-light {
    width: 100%;
    height: auto;
    transform: skew(0deg, -5deg);
    background-color: #F5F3F3;
    color: white;
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;

    @include mq($from: tablet) {
        padding-bottom: 3.5rem !important;
    }

    @include mq($from: desktop) {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;

    }
}


.paralle-BG-light .row-our-work,
.paralle-BG-light .row-top-features,
.paralle-BG-light .row-top-feature-image,
.paralle-BG-light .related-article-container,
.partners-carousel-container {
    transform: skew(0deg, 5deg);
    padding-top: 30px;
    text-align: left;
}

.paralle-BG-light h2,
.paralle-BG-light p {
    color: #413F3F;
}

.paralle-BG-light .column-top-feature-right {
    color: #413F3F;

    @include mq($from: tablet) {
        margin-top: 2.35rem !important;
    }

    @include mq($from: desktop) {
        margin-top: 2.3rem !important;
    }
}

.paralle-BG-light .row-top-feature-image {
    margin-top: -.5rem !important;
    text-align: center;
    padding-top: 0;

    @include mq($from: desktop) {
        margin-top: -2rem !important;
    }
}

.paralle-BG-light .row-top-feature-image img {
    margin-bottom: -10rem;
}

.article-main-image-noframe img {
    margin-bottom: -7rem;
    transform: skew(0deg, 5deg);
}