.heading--large {
    font-size: 2.75rem;
    font-weight: 700;
    text-align: left;
    line-height: 125%;
    letter-spacing: -.5px;

    @include mq($from: desktop) {
        font-size: 3.75rem;
        font-weight: 700;
        line-height: 115%;
        letter-spacing: -1.1px;
        text-align: left;
        margin-bottom: 1.5rem;
    }
}

.stats__heading {
    text-align: left;
    margin-bottom: 0.5rem;

}