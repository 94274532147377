.social {
    display: inline-block;

    &--vertical {
        width: 38px;
    }

    &__list {
        @extend %list;
        display: flex;
        justify-content: flex-start;

        &--vertical {
            flex-direction: column;
        }
    }

    &__list-item {
        margin: 0;
        flex: 0 1 70px;

        & a {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0;
            color: $white;
            background-color: none;
            opacity: .7;


            &:hover {
                opacity: 1;
                color: $primary-color;
            }

            &.facebook {
                background-color: #413F3F !important;

            }

            &.twitter {
                background-color: #413F3F !important;
            }


            &.linkedin {
                background-color: #413F3F !important;
            }

            &.youtube {
                background-color: #413F3F !important;
            }
        }
    }
}