.cards-carousel {

  position: relative;
  width: 100%; // It must be equal to $row-wide-width
  margin-bottom: 3.5rem;
  margin-top: 1.5rem;

  &--arrow {
    border-width: 0 2px 2px 0;
    opacity: 1;
    margin-top: 3px;
    border-right: solid $primary-color;
    border-bottom: solid $primary-color;
    padding: 5px;
  }

  &--left-button-wrapper,
  &--right-button-wrapper {
    width: 40px;
    position: absolute;
    z-index: 10;
    height: 40px;
    top: 45%;
    //background: rgba(0, 0, 0, .8);

    @include mq($from: tablet) {
      top: 40%;
      width: 48px;
      height: 48px;
    }

    &:empty {
      display: none;
    }

    .icon-button {
      display: block;
      height: 100%;
      width: 100%;
      position: relative;
      border: 0;
      cursor: pointer;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      opacity: 1;
      color: $primary-color;

      &:hover {
        background-color: $primary-color;

        .cards-carousel--arrow {
          border-color: #fff;
        }
      }
    }

    .i {
      display: none;
    }

    left: 0;
  }

  &--right-button-wrapper {
    display: none;

    @include mq($from: desktop) {
      display: block;
      right: -20px;
      left: auto;
    }
  }

  &--left-button-wrapper {
    right: auto;
    display: none;

    @include mq($from: desktop) {
      display: block;
      right: auto;
      left: -20px;
    }
  }

  &--content {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}

.row-our-work .card {
  display: inline-block;
  width: 90%;
  margin: 10px;

  @include mq($from: tablet) {
    width: 36%;
  }

  @include mq($from: desktop) {
    width: 21%;
  }

  &:first-child {
    margin-left: 0;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__content {
    text-align: left;
    white-space: normal;
  }

  &--portrait {
    .card {
      &__content-wrapper {
        min-height: 160px;

        @include mq($from: tablet) {
          min-height: 200px;
        }

        @include mq($from: desktop) {
          min-height: 205px;
        }
      }
    }
  }


}

.partners-carousel-container .carousel__slide {
  width: 46%;
  margin-right: 15px;
  background: none !important;
  padding: 2rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;


  @include mq($from: tablet) {
    width: 25%;
    margin-right: 25px;
    height: 180px;
  }

  @include mq($from: desktop) {
    width: 15%;
    margin-right: 25px;
    background: none !important;
    padding: 4rem;
    height: 220px;
  }
}


.partners-carousel-container .carousel {
  min-height: auto;
  max-height: 122px;
  margin: 2rem 0;

  @include mq($from: desktop) {
    min-height: 220px;
    margin: 2rem 0;

  }
}

.partners-carousel-container .carousel__slide img {
  height: auto;
}

.carousel {

  &__button {

    &--next,
    &--previous {
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      opacity: 1;
      color: $primary-color;

      &:hover {
        background-color: $primary-color;

        .arrow {
          border-color: #fff;
        }
      }

      .arrow {
        border-top: 2px solid $primary-color;
        border-right: 2px solid $primary-color;
      }

    }
  }
}

.carousel__list {

  overflow-x: scroll;

  @include mq($from: desktop) {
    overflow: hidden !important;
  }
}

@include mq($from: desktop, $until: 1500px) {
  .row-our-work .card--portrait .card__content-wrapper {
    min-height: 235px;
  }

}
