h1,
.heading--large,
.heading--large h1 {
  font-size: 2.75rem;
  font-weight: 700;
  text-align: left;
  line-height: 125%;
  letter-spacing: -.5px;

  @include mq($from: desktop) {
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 115%;
    letter-spacing: -1.1px;
    text-align: left;
    margin-bottom: 1.5rem;
  }

}

h2 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0px;
  text-align: left;
  margin: 1rem 0;

  @include mq($from: desktop) {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.25px;
    text-align: left;
  }

}

.row-article-body h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0px;
  text-align: left;
  margin: 2rem 0 1rem 0;


  @include mq($from: desktop) {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.25px;
    text-align: left;
    margin: 2.3rem 0 1.2rem 0;
  }
}


h3,
.card__title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0px;
  text-align: left;

  @include mq($from: desktop) {
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0px;
    text-align: left;
  }

}

h4 {
  font-size: 1.45rem;
  text-align: left;
  letter-spacing: 0;
  color: $dark-grey;
  font-weight: 400;
}

p {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1.3rem;
  line-height: 1.8em;

  @include mq($from: desktop) {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
  }
}

li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.8em;

  @include mq($from: desktop) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 400;
    text-align: left;
  }
}

.row-article-body {
  li a {
    color: #FFA207;
  }
}

.row-footer-container p {
  text-align: center;

  @include mq($from: tablet) {
    text-align: left;
  }

}

.row-top-features {
  p {
    margin: 0;
  }

  h2 {
    margin: 0;
  }
}

@media screen and (max-width: 64em) {



  .paralle-BG-light .row-top-feature-image img {
    margin-bottom: -8rem !important;
  }



  .article-main-image {
    border: 10px solid #fff !important;
    margin: -1rem 0 !important;
    margin: 10px !important;
  }

  .row-footer-container {


    .column--one-quarter:first-child {
      flex-direction: row;
      margin: 0 auto;
    }

    .column-copyright {
      max-height: 50px;
    }

    .social__list {
      justify-content: center;
    }

    .social__list-item {
      margin: 40px 20px;
      flex: none;
    }

  }



  .footer-background {
    background-position: bottom;
    background-repeat: repeat-x;
  }

  .textblock-footer-address p,
  .textblock-footer-contacts p {
    border-left: none !important;
  }

  .paralle-BG-dark,
  .paralle-BG-light {
    margin: 4rem 0 !important;
  }

  .row-metrics .metric {
    margin-bottom: 1rem;
  }

  .row-metrics .metric h3 {
    font-size: 2.25rem !important;
  }

  .blog-section-heading {
    margin-top: 4rem !important;
  }

  .our-products-right {
    padding-top: 50px !important;
  }

  .filter__refine--mobile-close {
    display: none;
  }



  .form-field--checkbox {
    max-width: 100% !important;
  }
}

.row-top-intro {
  max-width: 50rem !important;


}

.row-article-title,
.row-top-intro {
  p {
    font-size: 1.35rem;
    text-align: left;
    letter-spacing: 0;
    line-height: 2.2rem;
    color: #908484;
    font-weight: 400;

    @include mq($from: desktop) {
      font-size: 1.5rem;
    }

  }

}

.row-top-intro .textblock-intro {
  margin-bottom: 1.5rem;
}

.our-products-left h2 a {
  color: white;
}

.our-products-left .image {
  width: 157px;
}

.our-products-right {
  margin-bottom: -100px;
}

.circle-image img {
  border-radius: 50%;
  border: 10px solid white;
  box-shadow: 0 0 8px 0 rgb(5 5 5 / 15%)
}

.our-services {
  padding-top: 80px !important;
}



.quote-label {
  border-left: 1px solid $primary-color;
  padding-left: 20px;
  line-height: 110%;
  margin-bottom: 2rem;
}

.quote-label p {
  line-height: 130%;
}

.wastland-logo {
  text-align: left;
  padding-top: 2rem;
}

.wastland-logo img {
  width: 150px;
}

.updates-heading,
.blog-section-heading {
  text-align: left;
}

.category p {
  line-height: 100%;
}

.row-latest-blog {
  margin-top: 1.5rem !important;
  font-size: 1.625rem;
  font-weight: 600;

  .card__title--portrait {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;

  }
}

.row-latest-blog2 {
  margin-top: 25px !important;
  font-size: 1.625rem;
  font-weight: 600;
}


.row-latest-blog .card--portrait a {
  color: white;
}

.row-quote-container {
  max-width: 640px !important;
  margin-bottom: 4rem !important;
  margin-top: 2rem !important;

  & img {
    max-width: 250px;
  }

  @include mq($from: desktop) {
    margin-bottom: 5rem !important;
    margin-top: 4rem !important;
  }

}

.row-quote-container p {
  font-size: 1rem;
  text-align: center;

}

.textblock-quote {
  margin-top: 10px !important;
}

.textblock-quote p {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 2rem;

  @include mq($from: desktop) {
    font-size: 1.3rem;
  }
}

.row-footer-container {
  min-height: 220px;
}

.row-footer-container p {
  font-size: 1rem;
}

.textblock-footer-address p,
.textblock-footer-contacts p {
  border-left: 1px solid #CDCACA;
  padding-left: 15px;
}


/* ----artcile page -------*/

.row-article-title,
.row-article-body {
  max-width: 50rem !important;
}

.row-metrics {
  margin-top: 2rem !important;
}

.row-metrics .metric {
  background-image: url("/static/img/arrow-up-green.png");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 4.3rem;
}

.row-metrics .metric h3 {
  font-size: 3rem;
  line-height: 90%;
  font-weight: 600;
  margin: 0;
}

.row-metrics .metric p {
  font-size: 1rem !important;
  font-weight: 400;
  margin: 0;
}

.related-article-container .grid--three,
.row-list .grid--three {
  grid-gap: 25px;
}

/* ----listing page -------*/

.row-list .list__item {
  margin: 0;
  justify-items: flex-start;
}



.row-list-section-heading {
  margin-top: 2.75rem;
  margin-bottom: 1rem;

  @include mq($from: desktop) {
    margin-top: 4rem;
  }

}

.row-search-filters {
  margin-top: 0.3rem !important;

  @include mq($from: tablet) {
    margin-top: 1rem !important;
  }
}


.row-search-filters.row--search-result {
  .filter--search-refine {
    max-height: 3.5rem;
  }

  .search {
    border: none;
    box-shadow: 0 0 8px 0 rgb(5 5 5 / 15%);
    border-radius: 25px;
    margin-bottom: 1rem;

    @include mq($from: tablet) {
      width: 30rem;
    }


    input {
      background: none !important;
      height: 36px !important;
    }
  }

  .filter__reset {
    margin-top: 5px;
  }
}

.partners-carousel-container p,
.partners-carousel-container h2 {
  text-align: center;
}

.list_total_results {
  text-align: left;
  margin-bottom: 1rem;
  font-weight: 600;
}
