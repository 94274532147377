.button {

    max-width: max-content;
    height: max-content;
    font-size: 1rem;
    background-color: $primary-color;
    border-radius: 25px;
    text-transform: capitalize;
    box-shadow: 0 1px 4px 0 rgb(5 5 5 / 15%);
    min-width: auto !important;

    @include mq($from: desktop) {
        max-width: max-content;
    }

    &--cta {
        background-color: $primary-color;

        &:hover {
            background-color: #413F3F;
        }

    }
}

.button--dark {
    border: 2px $primary-color solid;
    background: none;
    color: $primary-color;
    margin-top: 4rem;
}

.button--dark:hover {
    color: white;
    background-color: $primary-color;
}

.button--link {
    border: none !important;
    box-shadow: none !important;
    font-weight: 400;
    margin-bottom: .5rem;
    padding: 5px;
    border-left: 1px solid $primary-color !important;
    line-height: 100%;
    text-align: left;
    background-color: rgba($color: #fff, $alpha: 0);
    border-radius: 0px;
    box-shadow: none;
}

.button--link:hover {
    color: white;
    background: $primary-color !important;
}

.row-quote-container .button--link {
    border: none !important;
}