.card {
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  height: 100%;

  &--dark-bg {
    background-color: #413F3F;
    color: white;

    a {
      color: white;
    }
  }
}


.row-latest-blog .column--half {
  max-width: 100%;

  &:first-child {
    .card__image img {
      max-height: 427px;
      width: 100%;
      object-fit: cover;
    }

  }


  @include mq($from: tablet) {
    max-width: 48.5%;
  }

  @include mq($from: desktop) {
    max-width: 49.2%;
  }
}


.row-latest-blog .card--overlay {
  max-height: 100%;

  @include mq($from: tablet) {
    min-height: 392px;
  }

  @include mq($from: desktop) {
    min-height: 427px;
  }
}


.row-latest-blog .card--portrait {
  background-color: #413F3F;
  color: white;
  min-height: 100%;

  @include mq($from: tablet) {
    min-height: 320px;
  }

  @include mq($from: desktop) {
    min-height: 427px;
  }
}

.row-latest-blog2 .grid--four,
.grid--two,
.paralle-BG-light .grid {
  grid-gap: 25px;
}

.card .badge {
  font-size: 1rem;
  font-weight: 400;
  color: $primary-color;
  padding-left: 10px;
  border-left: 1px solid $primary-color;
}

.row-latest-blog2 .card:last-child {
  margin-right: 0;
}

.card--overlay,
.card__image--overlay {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#00000000, #00000001, #00000040, #000000);
  }

  img {
    opacity: 1;
  }
}



.card__content--overlay {
  text-align: left;
}

.card--half-blog-card:hover,
.card--overlay:hover {
  .card__content--overlay {
    background-color: #413F3F;
  }

}

.card__title {
  font-size: 1.35rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;

  @include mq($from: desktop) {
    font-size: 1.45rem;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0px;
    text-align: left;
  }

}

.card__title a:hover {
  color: $primary-color;
}

.card__title a {
  border: none;
}


.card__image img:hover {
  opacity: .8;
}

.row-latest-blog .card__image img {
  max-height: 222px;
  width: 100%;
  object-fit: cover;
}

.row-latest-blog2 .card__image img {
  max-height: 222px;
  width: 100%;
  object-fit: cover;
}

.row-list .card__image img {

  object-fit: cover;

  @include mq($from: tablet) {
    max-height: 156px;
  }

  @include mq($from: desktop) {
    max-height: 235px;
  }

}
