/* manrope-200 - latin */
@font-face {
  font-display: block;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  src: url('/static/fonts/manrope-v15-latin-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: url('/static/fonts/manrope-v15-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-regular - latin */
@font-face {
  font-display: block;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/manrope-v15-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/manrope-v15-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-600 - latin */
@font-face {
  font-display: block;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: url('/static/fonts/manrope-v15-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-700 - latin */
@font-face {
  font-display: block;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/manrope-v15-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src: url('/static/fonts/manrope-v15-latin-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

// self hosted fonts above
//@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

/* todo: most places updated to use svg icons. keep these for the short term for legacy support.
  It will cause minimal additional data as fonts shouldn't be downloaded unless used in legacy places */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons&display=swap");
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url(""); // last font import seems to break so adding an empty one