.sticky--header {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 50;
    transition: all 0.15s linear;
    overflow: auto;
}

.sticky--header.shadow {
    box-shadow: 0 1px 6px 0 rgb(5 5 5 / 15%);
}

.menu {

    &--desktop-wrapper {
        display: block;
    }

    &--desktop {
        position: relative;
        margin: 1.5rem 0 1.5rem 0;
        flex-grow: 0;
        padding-bottom: 1rem;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
        list-style: none;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 0;
        display: none;

        @include mq($from: desktop) {
            display: flex;
            flex-direction: row;
        }

        a:hover {
            color: $primary-color;
        }
    }

    &--mobile-wrapper {
        background-color: white;
        position: sticky;
        top: 0;
        text-align: left;
        min-height: 60px;
        z-index: 50;
        display: flex;

        @include mq($from: desktop) {
            display: none;

        }
    }


    &--mobile-logo {
        width: 80px;
        top: 15px;
        left: 42%;

        @include mq($from: mobile) {
            visibility: visible !important;
        }

        @include mq($from: tablet) {
            left: 45%;
            visibility: visible !important;
        }

        @include mq($from: desktop) {
            visibility: hidden !important;
        }

    }

    &--mobile-items {
        background-color: #413F3F;
        font-size: 1.5rem;
        line-height: 2.5rem;
        width: 100vw;
        height: 100vh;
        padding: 2rem 1rem;
        margin-top: 0;
        cursor: pointer;
        margin-left: -10px;
        visibility: visible;
        display: flex;
        flex-direction: column;

        @include mq($from: desktop) {
            visibility: hidden;

        }
    }

    &--item--link {
        text-align: center;

        @include mq($from: desktop) {
            text-align: left;
        }

    }

    &--desktop-logo {
        visibility: hidden !important;
        width: 14rem;
        height: 8rem;
        width: auto !important;
        height: auto !important;

        &>a {
            flex-shrink: 0 !important;
        }

        @include mq($from: desktop) {
            visibility: visible !important;
            width: 14rem;
            height: 8rem;
            flex-shrink: 0;
            margin-right: 2rem;
        }
    }
}


.menu--desktop-wrapper .menu--desktop__logo-wrapper {
    flex-shrink: 0;
}

.menu--desktop__logo-wrapper img {
    width: 130px;
    height: auto;
}

.search-content--expanded__wrapper {
    width: 90%;
    margin: 10px auto;

    @include mq($from: tablet) {
        margin: 20px auto;
    }

    @include mq($from: desktop) {
        width: 20rem;
        margin: 0;
    }
}

.search-content--expanded__input {
    display: block;
    width: 100%;
    padding: 4px;
    display: block;
    background-color: white;
    width: 100%;
    border-radius: 1.5rem;
    border: none !important;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 20px;
    padding-right: 3px;
    height: 40px;
    box-shadow: 0 1px 4px 0 rgb(5 5 5 / 15%);
}

.search-content--expanded__icon {
    height: 1.25rem;
    width: 1.25rem;
    fill: rgb(120, 120, 120);
    top: 8px;
    position: absolute;
    right: 8px;
}

.menu--desktop .search-content--expanded__input:focus {
    border: 2px solid #FFA207 !important;
}



.menu--mobile-button {
    margin-top: 15px;
    text-align: left;
    background: none;
    border: none;
    padding: 0;
}

.menu--mobile-header {
    display: flex;
    justify-items: center;
    background-color: white;
}

.menu--mobile-close-icon {
    color: #FFA207;
}



.menu--mobile-items li {
    list-style: none;
    text-align: center;
}

.menu--mobile-items li a {
    color: #FFA207;
}



@media screen and (max-width: 48em) {
    .menu--desktop__logo-wrapper {
        display: none;
    }

    .search-content--expanded__wrapper {
        margin-top: 3rem;
        width: 100%;
    }
}

.search-content--results__wrapper {
    width: 90%;
    height: 15rem;
    border: none;
    box-shadow: 0 1px 4px 0 rgb(5 5 5 / 20%);
    border-radius: .5rem;
    z-index: 99999;
    position: fixed;
    text-align: left;
    overflow-y: auto;
    background-color: $white;
    margin: 0;

    @include mq($from: tablet) {
        margin-left: 30px;
    }

    @include mq($from: desktop) {
        margin: 0;
        width: 22rem;
    }

    .search-content--results__wrapper {
        width: 20rem;
        height: max-content;
        box-shadow: none;
        padding: 10px;
        background: none;
        position: relative;
    }
}

.search-content--results__content {
    position: absolute;
    background: none;
    display: flex;
    flex-direction: column;
    border: none;
    max-width: 20rem;
    overflow: hidden;
}


.search-content--results__wrapper--message .pt-2 {
    font-weight: 700;
    margin-bottom: .5rem;
}

.search-content--results__title {
    text-align: left;
    width: 15rem;
    text-overflow: ellipsis;
    margin-top: -4px;
    margin-left: 0;
    line-height: 135%;
}

.search-content--results__image {
    padding-right: 8px;
    width: 80px;
    max-height: 60px;
    object-fit: cover;
}