$primary-color: #FFA207;
/*nearly black*/
$secondary-color: #a77500;
/*cyan 700*/
$tertiary-color: #f06292;
/*pink 300*/

$link-color: #FFA207;
/*cyan 700*/

$primary-button-color: #dda20f;
/*cyan 700*/
$secondary-button-color: #f06292;
/*pink 300*/
$cta-button-color: #4caf50;
/*green*/
$alert-button-color: #cb1417;
/*red*/
$light-button-color: #999999;
/*grey medium*/
$dark-button-color: #625f5c;
/*grey warm*/

$primary-badge-color: #03a9f4;
/*blue*/
$secondary-badge-color: #9c27b0;
/*purple*/
$alert-badge-color: #f44336;
/*red*/
$success-badge-color: #4caf50;
/*green*/
$info-badge-color: #00bcd4;
/*cyan*/
$light-badge-color: #eeeeee;
/*grey light*/
$dark-grey: #908484;
/*grey dark*/

$nearly-black: #413F3F;

$cta-button-color: #4285F4;
$primary-font: "Manrope",
Helvetica,
Arial,
sans-serif;

/* Show the current breakpoint in the top right corner of the viewport:*/
$mq-breakpoints: (mobile: 321px, mobileBig: 425px, phablet: 660px, tablet: 768px, desktopSmall: 960px, desktop: 1024px, wide: 1180px);